import ReactAudioPlayer from 'react-audio-player';
import '../App.css';
import VaFile from '../constants/va.json'

function Va() {

  return (
    <div>
        <p className='TextPrimary'>
          Here are several VA samples categorized!
        </p>

        { 
          VaFile.map((val, index1) => {
              return ( <div key={index1}>
                  <p className='TextHeader'>{val.category}</p>
                  <div className='VaCategoryAudioContainer'>
                    {
                      val.files.map((file, index2) => {
                        return (
                            <ReactAudioPlayer
                              className='AudioFile'
                              src={file}
                              controls
                              key={index2}
                            />
                        )
                      })
                    }
                  </div>
              </div>)
          })
        }
    </div>
  );
}

export default Va;
